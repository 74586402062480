import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { Button, Card, Spinner } from 'ds/components'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { InvoiceTable } from 'lib/common/InvoiceTable'

import { invoiceService } from 'lib/services/invoiceService'
import { getManualPagination } from 'lib/utils/getManualPagination'
import { parseAsInteger, parseAsString, useQueryStates } from 'next-usequerystate'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

const initFilter = {
    limit: parseAsInteger.withDefault(25),
    offset: parseAsInteger.withDefault(0),
    sort_by: parseAsString.withDefault('-amount_total'),
}

export const InvoicesDue = ({ organization_id, currency, dateParam, start, end }) => {
    const [filter, setFilter] = useQueryStates(initFilter, { shallow: false })

    const queryParams = useMemo(() => {
        return {
            components: 'customer',
            date_range_param: dateParam,
            organization_id,
            currency,
            settled_state: 'not_settled',
            start,
            end,
            sort_by: filter.sort_by,
            limit: filter.limit,
            offset: filter.offset,
        }
    }, [filter, organization_id, currency, dateParam, start, end])

    const { isLoading, error, data, isPlaceholderData } = useQuery({
        queryKey: ['invoice', queryParams],
        queryFn: () => invoiceService.getInvoicesPaginated(queryParams),
        placeholderData: keepPreviousData,
    })

    const errorMessage = getErrorFromQuery(error, data)

    if (isLoading) return <Spinner />
    if (errorMessage || !data) {
        return <ErrorBanner>Ha ocurrido un error: {errorMessage}</ErrorBanner>
    }

    const { invoices, paginationMetadata } = data

    return (
        <Card>
            <div className="d-flex">
                <div className="flex-fill">
                    <h3>Por Cobrar del Periodo</h3>
                </div>
                <div>
                    <Button href="/invoices" variant="primaryText">
                        Cobranza →
                    </Button>
                </div>
            </div>
            <InvoiceTable
                isLoading={isPlaceholderData}
                invoices={invoices}
                onDownload={() => invoiceService.downloadInvoices(queryParams)}
                onSort={(sortBy) =>
                    setFilter({ ...filter, sort_by: sortBy, offset: null })
                }
                manualPagination={getManualPagination({
                    paginationMetadata,
                    filter,
                    setFilter,
                })}
            />
        </Card>
    )
}

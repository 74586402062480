import { FC, useState, useContext, useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { ReceivablesAgingKpis } from './ReceivablesAgingKpis'
import { DashboardRevenueChart } from './DashboardRevenueChart'
import { ReceivablesAgingChart } from '../ReceivablesAgingChart'
import { PeriodicKpis } from './PeriodicKpis'
import { OrganizationSelect } from 'lib/common/OrganizationSelect'
import { CurrencySelectButtons } from 'lib/common/CurrencySelect'
import { SessionContext } from 'lib/hoc/withSession'
import { Currency, isUSD } from 'lib/models/currency'

export const AccountsReceivablesDashboard: FC = () => {
    const { primaryCurrency } = useContext(SessionContext)

    const [currency, setCurrency] = useLocalStorage<Currency>(
        'dashboard_currency',
        primaryCurrency
    )

    useEffect(() => {
        if (isUSD(primaryCurrency) && !isUSD(currency)) {
            setCurrency(Currency.USD)
        }
    }, [currency, primaryCurrency, setCurrency])

    const [organizationId, setOrganizationId] = useState<number | null>(null)

    return (
        <>
            <div className="d-flex gap-3">
                {/* Move mb-3 class to children incase they are both not rendered */}
                <CurrencySelectButtons
                    className="mb-3"
                    currency={currency}
                    onChange={(val) => setCurrency(val || primaryCurrency)}
                />
                <OrganizationSelect
                    className="mb-3"
                    organizationId={organizationId}
                    onChange={setOrganizationId}
                />
            </div>

            <ReceivablesAgingKpis currency={currency} organization_id={organizationId} />

            <div className="row">
                <div className="col-xl-6">
                    <DashboardRevenueChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
                <div className="col-xl-6">
                    <ReceivablesAgingChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
            </div>

            <PeriodicKpis currency={currency} organization_id={organizationId} />
        </>
    )
}

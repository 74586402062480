import { useContext, useState } from 'react'

import Link from 'next/link'
import { Modal, DropdownButton } from 'ds/components'
import { RecordPaymentFormLoader } from 'lib/common/RecordPaymentFormLoader'

import { SessionContext } from 'lib/hoc/withSession'
import { hasPermission } from 'lib/utils/hasPermission'
import { Permission } from 'lib/models/permission'
import { CreateContractModal } from '../CreateContractModal'

export const CreateAnythingButton = () => {
    const [showModal, setShowModal] = useState('')

    const { session } = useContext(SessionContext)

    const canCreateCustomer = hasPermission(session, Permission.WRITE_CUSTOMER)
    const canCreateInvoice = hasPermission(session, Permission.WRITE_INVOICE)
    const canCreatePayment = hasPermission(session, Permission.WRITE_PAYMENT)

    if (!canCreateCustomer && !canCreateInvoice && !canCreatePayment) return null

    return (
        <>
            <DropdownButton
                size="sm"
                variant="primary"
                iconRight="chevron-down"
                buttonText="Crear"
            >
                {canCreateInvoice ? <Link href={`/invoices/create`}>Cobro</Link> : null}
                {/* TODO: Uncomment before when contracts are ready */}
                {/* {canCreateContract ? (
                    <a onClick={() => setShowModal('contract')}>Contrato</a>
                ) : null} */}
                {canCreatePayment ? (
                    <a onClick={() => setShowModal('payment')}>Pago</a>
                ) : null}
                {canCreateCustomer ? (
                    <Link href={`/customers/create`}>Cliente</Link>
                ) : null}
            </DropdownButton>

            {showModal === 'payment' ? (
                <Modal title="Registrar pago" onClose={() => setShowModal('')}>
                    <RecordPaymentFormLoader onSuccess={() => setShowModal('')} />
                </Modal>
            ) : null}

            {showModal === 'contract' ? (
                <CreateContractModal onClose={() => setShowModal('')} />
            ) : null}
        </>
    )
}
